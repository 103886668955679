import './bootstrap';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'

window.Alpine = Alpine;

export const init = (registerComponents = () => {}) => {
    Alpine.plugin(intersect);
    document.addEventListener('alpine:init', registerComponents);
    Alpine.start();
}
